@import '../_base/mixins';

html * {
  box-sizing: border-box;
}
// Basic bon-grid like for caas items
.maas-item {
  @include variableColor(--text-base, #000);
  text-align: center;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
  }

  &
  > *:first-child:not(.m-video-show-description):not(.games-navigation):not(.aol-notification-bell):not(.aol-notification-banner):not(.m-notifications-banner):not(.notifications-wrap-overlay):not(.m-standard_mobile):not(.m-standard_automated_mobile):not(.m-ad-blocker):not(.m-video-header):not(.m-AolFooter):not(.m-skimlinks):not(.m-taboola) {
    margin-bottom: 30px;

    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
    }
  }
}

/**
* Style applied to dynamically-generated Universal Ad Call classnames
*/
.m-ad.is-blank {
  margin-bottom: 0;
}

.bg-shimmer {
  @include addBackgroundShimmer(500px, 1s);
}
