@charset "UTF-8";

@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
  }
}

@mixin list-unstyled {
  list-style: none;
  padding-left: 0;
}

// Responsive mixins
@mixin media-tablet-large {
  @media screen and (max-width: 1100px) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (min-width: 901px) and (max-width: 1100px) {
    @content;
  }
}

@mixin media-tablet-small {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin media-mobile-large {
  @media screen and (max-width: 753px) {
    @content;
  }
}

@mixin media-mobile {
  @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
    @content;
  }
}

@mixin media-mobile-small {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin media-mobile-smaller {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin addBackgroundShimmerWithColor($max-width, $speed, $bg, $fg) {
  @keyframes placeHolderShimmer {
    0% {
      background-position: -$max-width;
    }

    100% {
      background-position: $max-width;
    }
  }
  animation-duration: $speed;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, $bg 5%, $fg 20%, $bg 35%);
  background-size: $max-width;
}

@mixin addBackgroundShimmer($max-width, $speed) {
  @include addBackgroundShimmerWithColor($max-width, $speed, #eee, #ddd);
}

@mixin variableProperty($property, $color-var, $fallback, $important: false){
  // $Fallback is used on IE as it does not support CSS3 vars
  @if $important {
    #{$property}: $fallback !important;
    #{$property}: var($color-var) !important;
  } @else {
    #{$property}: $fallback;
    #{$property}: var($color-var);
  }
}

@mixin variableColor($color-var, $fallback, $important: false){
  @include variableProperty('color', $color-var, $fallback, $important);
}

@mixin variableBgColor($color-var, $fallback, $important: false){
  @include variableProperty('background-color', $color-var, $fallback, $important);
}

@mixin variableBorderColor($color-var, $fallback, $important: false){
  @include variableProperty('border-color', $color-var, $fallback, $important);
}

@mixin variableBorderStyle($color-var, $fallback, $important: false){
  @include variableProperty('border-style', $color-var, $fallback, $important);
}

@mixin variableFill($color-var, $fallback, $important: false){
  @include variableProperty('fill', $color-var, $fallback, $important);
}
