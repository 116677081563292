// These are used to generate the class names, which will map to their corresponding px width
$breakpoint-classes: (xxs xs s m l xl xxl);

// These are needed as we renamed their defaults which were `large` and `small`
$print-breakpoint: l;
$-zf-zero-breakpoint: xxs;

// Breakpoint px
$breakpoints: (
  "xxs": 0,
  "xs": 601px,
  "s": 901px,
  "m": 1124px,
  "medium": 1124px, //  This medium var is only here to prevent a warning on scss compilation
  "l": 1281px,
  "xl": 1441px,
  "xxl": 1680px
);

// These are the gutter widths used when .grid-margin-x/y or .grid-padding-x/y is set
$gutters: (
  "xxs": 20px,
  "xs": 20px,
  "s": 20px,
  "m": 40px,
  "l": 40px,
  "xxl": 40px,
  "xl": 40px
);
$grid-margin-gutters: $gutters;
$grid-padding-gutters: $gutters;

// This is the margin used on the `grid-container` element. The values are split in half for either side of the container.
$grid-container-padding: (
  "xxs": 40px,
  "xs": 40px,
  "s": 80px,
  "m": 80px,
  "l": 160px,
  "xl": 160px,
  "xxl": 160px,
);

// `grid-container` element width
$global-width: 1680px;

// This import does not include foundation css, just the vars, mixins, config etc
@import '~foundation-sites/scss/foundation';
