$cdn: 'https://s.yimg.com/cv/apiv2/aolfp/fonts/basis_grotesque/';
$font-name: 'Basis Grotesque Pro';


/*
  We do not have all font weights and styles for Basis Grotesque Pro.
  Mapping is defined here: https://www.figma.com/design/KESWsBnhiqUm1xXhG45VzX/Yellow-Submarine?node-id=712-64551&p=f&t=LVunTRpRoYNbfJMH-0
  As such this is the mapping we are using:
  | Current Weight   | New Weight    |
  |------------------|---------------|
  | Black (900)      | Black (900)   |
  | ExtraBold (800)  | Black (900)   |
  | Bold (700)       | Black (900)   |
  | SemiBold (600)   | Bold (700)    |
  | Medium (500)     | Medium (500)  |
  | Regular (400)    | Regular (400) |
  | Light (300)      | Regular (400) |
  | ExtraLight (200) | Regular (400) |
  | Thin (100)       | Regular (400) |
*/

@font-face {
  font-family: $font-name;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-Black.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-Black.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-BlackItalic.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-BlackItalic.woff') format('woff');
}

// [Start] These all map to black (900)
@font-face {
  font-family: $font-name;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-Black.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-Black.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-BlackItalic.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-BlackItalic.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-Black.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-Black.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-BlackItalic.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-BlackItalic.woff') format('woff');
}
// [End] These all map to black (900)

// [Start] These all map to bold (700)
@font-face {
  font-family: $font-name;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-Bold.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-Bold.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-BoldItalic.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-BoldItalic.woff') format('woff');
}
// [End] These all map to bold (700)

@font-face {
  font-family: $font-name;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-Medium.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-Medium.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-MediumItalic.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-Regular.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-Regular.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-RegularItalic.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-RegularItalic.woff') format('woff');
}

// [Start] These all map to Regular (400)
@font-face {
  font-family: $font-name;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-Regular.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-Regular.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-RegularItalic.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-Regular.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-Regular.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-RegularItalic.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-Regular.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-Regular.woff') format('woff');
}

@font-face {
  font-family: $font-name;
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$cdn}BasisGrotesque-RegularItalic.woff2') format('woff2'),
    url('#{$cdn}BasisGrotesque-RegularItalic.woff') format('woff');
}
// [End] These all map to Regular (400)
