@media (forced-colors: active) {
  /* forced colors are active */
  button {
    outline: 1px solid transparent;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  /* High Contrast #1, #2 or Black is active (or custom theme) */
  #app svg, #app .m-weather#m-weather__minimal .weather-details__day--icon svg {
    fill: $a11y-prefer-dark;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  /* High Contrast White is active (or custom theme) */
  #app svg, #app .m-weather#m-weather__minimal .weather-details__day--icon svg {
    fill:  $a11y-prefer-light;
  }
}
