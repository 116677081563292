@import "../_base/config";

// SCSS vars must be wrapped in #{} to be declared as CSS3 vars
// See: https://sass-lang.com/documentation/breaking-changes/css-vars

:root{
  --mail-bgc: #{$gray-bg-mail};
  --text-base: #{$batcave};
  --text-gray: #{$dolphin};
  --accent-color: #{$dory};
  --hover-color: #{$dory-hover};
  --bg-accent: #{$white};
  --bg-accent-dark: #{$blue-65};
  --mail-border-color: #{$white};
  --mail-bg-new: #{$white};
  --mail-bg-hover:#{$marshmallow};
  --mail-border-style:none;

  --font-family-base:#{$font-family-base};
  --font-family-heading:#{$font-family-poppins};
  --font-family-medicare:#{$font-family-poltawski};

  // These are used to aid in the A/B bucketing of the rebrand, should be cleaned up afterwards
  --cb-small-heading-line-height: 24px;
  --cb-small-heading-font-size: 14px;
  --cb-medium-heading-line-height: 20px;
  --cb-medium-heading-font-size: 14px;
  --cb-medium-heading-font-weight: 700;
  --cb-medium-heading-font-size-1441: 13px;
  --cb-large-heading-line-height: 24px;
  --cb-large-heading-font-size: 16px;
  --cb-xlarge-heading-line-height: 28px;
  --cb-xlarge-heading-font-size: 20px;
  --header-search-bg-color: #{$Running-Man-Yellow-25};
  --header-search-border-color: transparent;
}

/* ================================================== */
/* [Start of Rebrand Bucketing Variable Definitions]  */
/* ================================================== */

// This .rebrand class gets added by td-app-aol
html.rebrand {
  $basis-font: "Basis Grotesque Pro";
  --font-family-base:#{$basis-font}, #{$font-family-base};
  --font-family-heading:#{$basis-font}, #{$font-family-poppins};
  --font-family-medicare:#{$basis-font}, #{$font-family-poltawski};
  --cb-small-heading-line-height: 20px;
  --cb-small-heading-font-size: 16px;
  --cb-medium-heading-line-height: 24px;
  --cb-medium-heading-font-size: 18px;
  --cb-medium-heading-font-weight: 600;
  --cb-medium-heading-font-size-1441: 16px;
  --cb-large-heading-line-height: 24px;
  --cb-large-heading-font-size: 18px;
  --cb-xlarge-heading-line-height: 24px;
  --cb-xlarge-heading-font-size: 20px;
}

/* ================================================== */
/* [End of Rebrand Bucketing Variable Definitions]    */
/* ================================================== */
