@import "../_common/fuji_colors"  ;
@import "grid_config"  ;

//== Breakpoints
$breakpoints_original: (
  screen-xxs: 479px,
  screen-xs: 480px,
  screen-sm: 754px,
  screen-md: 900px,
  screen-lg: 1128px,
  screen-xl: 1265px,
  screen-xxl: 1450px
) !default;

//== Colors
$off-white: #d3d3d3;
$blue: #0F69FF;
$blue-hover: #5cadff;
$red: #DA001A;
$red-lighter: #fe7b7a;
$pink: #e81d82;
$a11y-pink: #de187b;
$a11y-pink-hover: lighten($a11y-pink, 20%);
$pink_hover: #ed4a9b;
$fav-pink: #e6248a;
$sidenav-text: #444;
$purple: #4e01ca;
$yellow: #fea60a;
$green: #00804D;
$owl: #E1E5EA;
$white: #FFFFFF;

$gray-base: #000 !default;
$gray-base-light: lighten($gray-base, 6.75%); // #111
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$a11y-gray-light: #737177;
$a11y-dark-gray: #767676;
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$gray-background: rgba(248, 248, 248, 0.97); // #f8f8f8f8
$gray-medium: #cbcbcb;
$gray-border: #e0e0e0;
$white-gray: #f1f1f1;
$dropdown-border-gray: #e3e3e3;
$dropdown-background-gray: #f2f2f2;
$gray-bg-mail: #f9f9f9;
// Legacy Releases values (note Irish spelling)
$grey-dark: #333;
$grey-medium: #666;
$grey-light: #999;
$white-grey: #f1f1f1;

$brand-primary: darken(#428bca, 6.5%) !default; // #337ab7
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

//== Channel colors
$brand-news: $blue;
$brand-news-hover: lighten($brand-news, 8%) !default; // #5cadff
$brand-entertainment: #f22d2d;
$brand-entertainment-hover: lighten($brand-entertainment, 8%) !default; // #f15457
$brand-finance: #16cef6;
$brand-finance-hover: lighten($brand-finance, 8%) !default; // #3dd6f8
$brand-lifestyle: #fb8585;
$brand-lifestyle-hover: lighten($brand-lifestyle, 8%) !default; // #faadae
$brand-sports: #364b65;
$brand-sports-hover: lighten($brand-sports, 8%) !default; // #445f80
$brand-games: #0047FF !default;
$brand-games-hover: lighten($brand-games, 8%) !default; // #f08166
$brand-motoring: #00b398 !default;
$brand-motoring-hover: lighten($brand-motoring, 8%) !default;
$brand-shop: #fb8585 !default;
$brand-shop-hover: lighten($brand-shop, 8%) !default; // #f08166
$a11y-weather-dark-grey: #404040;
$a11y-weather-dark-blue: #053561;
$a11y-weather-hover: #006F98;

$brand-video: #4a4a4a !default;
$brand-video-hover: lighten($brand-video, 8%) !default;

$brand-coronavirus: #d82138 !default;
$brand-coronavirus-hover: lighten($brand-coronavirus, 8%) !default;

$brand-horoscopes: #b61bb8 !default;
$brand-horoscopes-hover: lighten($brand-horoscopes, 8%) !default;

$social-facebook: #3b5998;
$social-facebook-hover: #4c70ba;
$social-twitter: #4099ff;
$social-twitter-hover: #6cb7f0;
$social-pinterest: #cb2027;
$social-email: #b2d3e1;
$social-email-hover: lighten($social-email, 8%) !default;

//== Animations
$speed: 0.2s;
$speed-slower: 0.4s;
$speed-slowest: 0.6s;
$ease: cubic-bezier(0.23, 1, 0.32, 1);

//== Fonts
$font-family-sans-serif: '_OpenSans', 'OpenSans', 'Helvetica Neue', helvetica, arial, sans-serif !default;
$font-family-serif: georgia, 'Times New Roman', times, serif !default;
$font-family-monospace: menlo, monaco, consolas, 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-poppins: 'Poppins', $font-family-base;
$font-family-poltawski: 'Poltawski Nowy' !default;

$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // ~12px

//== Misc
//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // ~20px

$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

$base-border-color: #f1f1f1 !default;
$base-border-radius: 3px !default;

$link-color: $gray-dark !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

$max-article-text-width: 620px;

//== Buttons
$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;

//== Grid system
$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;

$screen-xs: map-get($breakpoints_original, 'screen-xs') !default;
$screen-xs-min: $screen-xs !default;

$screen-sm: map-get($breakpoints_original, 'screen-sm') !default;
$screen-sm-min: $screen-sm !default;

$screen-md: map-get($breakpoints_original, 'screen-md') !default;
$screen-md-min: $screen-md !default;

$screen-lg: map-get($breakpoints_original, 'screen-lg') !default;
$screen-lg-min: $screen-lg !default;

$screen-xl: map-get($breakpoints_original, 'screen-xl') !default;
$screen-xl-min: $screen-xl !default;

$container-tablet: (720px + $grid-gutter-width) !default;
$container-sm: $container-tablet !default;

$container-desktop: (940px + $grid-gutter-width) !default;
$container-md: $container-desktop !default;

$container-large-desktop: (1140px + $grid-gutter-width) !default;
$container-lg: $container-large-desktop !default;

//==Left Navigation
$left-nav-width-collapsed: 54px !default;
$left-nav-width: 185px !default;

//==Header
$header-height: 74px !default;
$header-height-subnav: 28px !default;
$header-height-search: 34px !default;
$header-height-mobile: 74px !default;

//==Right Rail
$right-rail-width: (300px + $grid-gutter-width) !default;

//==Misc
$floating-player-height: 163.125px !default;
$floating-player-width: 290px !default;
$ntk-article-background: #464e56;
$brand-games-category-background-hover: rgba(0, 0, 0, 0.8);
$product-carousel-hover: rgba(0, 0, 0, 0.6);
$z-index-slideshow: 3000003;
$featured-video-background: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0.65) 100%
);
$recipe-grad: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0.1) 50%,
  rgba(0, 0, 0, 0.7) 100%
);
$blue-65: rgba($blue, 0.65);
//=high contrast mode
$a11y-prefer-dark: #fff;
$a11y-prefer-light: #000;
