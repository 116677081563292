@font-face {
  font-family: 'Poltawski Nowy';
  font-weight: 400;
  font-style: italic;
  src:
    url('https://s.yimg.com/cv/apiv2/aolfp/fonts/poltawski_nowy/Poltawski_Nowy-RegularItalic.woff2') format('woff2'),
    url('https://s.yimg.com/cv/apiv2/aolfp/fonts/poltawski_nowy/Poltawski_Nowy-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'Poltawski Nowy';
  font-weight: 400;
  font-style: normal;
  src:
    url('https://s.yimg.com/cv/apiv2/aolfp/fonts/poltawski_nowy/Poltawski_Nowy-Regular.woff2') format('woff2'),
    url('https://s.yimg.com/cv/apiv2/aolfp/fonts/poltawski_nowy/Poltawski_Nowy-Regular.woff') format('woff');
}
