
// We are currently including only the xy-grid and flex classes from foundation
@include foundation-xy-grid-classes();
@include foundation-flex-classes;
@include foundation-visibility-classes;

// This is needed for this ticket: https://ouryahoo.atlassian.net/browse/GM-12942
.D\(n\)--landscape{
  @extend .hide-for-landscape
}

// This class provides the correct row offsets to cater for the lnav
.lnav-offset{
  &:before{
    @extend .s-offset-1;
    @extend .l-offset-2;
    content: '';
  }
}

.s-lnav-offset{
  &:before{
    @extend .s-offset-1;
    content: '';
  }
}


// This allows the containers to be edge-to-edge on xxs size devices
.xxs-e2e{
  @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
    @include xy-gutters($gutters:map-get($grid-container-padding, xxs), $negative: true);
    @include xy-gutters($gutters:map-get($grid-container-padding, xxs), $gutter-type: padding);
  }
}

.xxs-e2e--noPad{
  @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
    @include xy-gutters($gutters:map-get($grid-container-padding, xxs), $negative: true);
    @include xy-gutters($gutters:0px, $gutter-type: padding);
    // this is needed as gird-margin-x elems, have negative margin which usually lives within the container padding
    overflow: hidden;
    overflow: clip;
  }
}

.xxs-padding {
  @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
    @include xy-gutters($gutters: map-get($grid-container-padding, xxs), $gutter-type: padding);
  }
}

// This allows the containers to be edge-to-edge on xs size devices
.xs-e2e{
  @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
    @include xy-gutters($gutters:map-get($grid-container-padding, xs), $negative: true);
    @include xy-gutters($gutters:map-get($grid-container-padding, xs), $gutter-type: padding);
  }
}

.xs-e2e--noPad{
  @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
    @include xy-gutters($gutters:map-get($grid-container-padding, xs), $negative: true);
    @include xy-gutters($gutters:0px, $gutter-type: padding);
    // this is needed as gird-margin-x elems, have negative margin which usually lives within the container padding
    overflow: hidden;
    overflow: clip;
  }
}

.xs-padding {
  @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px)) {
    @include xy-gutters($gutters: map-get($grid-container-padding, xxs), $gutter-type: padding);
  }
}
