$poppins_cdn: 'https://s.yimg.com/cv/apiv2/aolfp/fonts/poppins/';

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-Black.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-Black.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-BlackItalic.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-BlackItalic.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-Extrabold.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-Extrabold.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-Extrabold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-ExtraboldItalic.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-ExtraboldItalic.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-ExtraboldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-Bold.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-Bold.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-BoldItalic.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-BoldItalic.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-Semibold.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-Semibold.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-SemiboldItalic.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-SemiboldItalic.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-SemiboldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-Medium.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-Medium.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-MediumItalic.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-MediumItalic.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-Regular.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-Regular.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-RegularItalic.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-RegularItalic.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-Light.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-Light.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-LightItalic.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-LightItalic.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-ExtraLight.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-ExtraLight.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-ExtraLightItalic.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-ExtraLightItalic.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-Thin.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-Thin.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('#{$poppins_cdn}Poppins-ThinItalic.woff2') format('woff2'),
    url('#{$poppins_cdn}Poppins-ThinItalic.woff') format('woff'),
    url('#{$poppins_cdn}Poppins-ThinItalic.ttf') format('truetype');
}
